import React from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

import { ChevronRight } from '@tofu/shared/ui/atoms/icons'

import { Arrow } from '../scrolling-slider-arrow'

export function ScrollingSliderArrowRight() {
  const { isLastItemVisible, scrollNext, visibleElements } =
    React.useContext(VisibilityContext)

  const [disabled, setDisabled] = React.useState(
    visibleElements.length === 0 && isLastItemVisible
  )
  React.useEffect(() => {
    if (visibleElements.length > 0) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleElements])

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <ChevronRight />
    </Arrow>
  )
}
