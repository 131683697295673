import { Grid } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'

import { Box } from '@tofu/shared/ui/atoms/box'
import { List } from '@tofu/shared/ui/atoms/list'
import { Container } from '@tofu/shared/ui/atoms/container'
import { getFromMap } from '@tofu/shared/utils/get-from-map'
import { linkResolver } from '@tofu/shared/utils/link-resolver'
import { ScrollingSlider } from '@tofu/shared/ui/molecules/scrolling-slider'

import { SliceImageGridItem } from './slice-image-grid-item'
import { containerMap } from './slice-image-grid.constants'
import { TSliceImageGrid, TRenderGridItem } from './slice-image-grid.types'

export const SliceImageGrid: TSliceImageGrid = ({
  items = [],
  primary,
  id,
  ...rest
}) => {
  const {
    width,
    max_images_per_row,
    max_item_width,
    should_scroll_on_mobile,
    background_colour
  } = primary || {}

  const maxItemsPerRow =
    max_images_per_row === 'auto' ? items.length : max_images_per_row
  const maxContainerWidth = getFromMap(containerMap, width) || 0

  const renderGridItem: TRenderGridItem = (item, index) => (
    <SliceImageGridItem
      key={index}
      itemId={index}
      // issues with Prismic Generated Types prismicT.LinkToMediaField doesn't work without adding
      // <string, string, unknown, 'filled'>
      // hoping this goes away when we switch to the slice machine
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image={item.image}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link={linkResolver(item?.link)}
      description={item?.description}
      minWidth={max_item_width}
    />
  )

  if (isEmpty(items)) return null

  return (
    <Box bg={background_colour}>
      <Container
        key={id}
        maxW={maxContainerWidth}
        px={should_scroll_on_mobile ? 'none' : 4}
        {...rest}
      >
        {should_scroll_on_mobile && (
          <Box display={['block', 'block', 'none', 'none']} as='ul'>
            <ScrollingSlider>{items.map(renderGridItem)}</ScrollingSlider>
          </Box>
        )}

        <Grid
          as={List}
          justifyContent='center'
          justifyItems='center'
          gap={[4, 8, 8, 8]}
          display={
            should_scroll_on_mobile ? ['none', 'none', 'grid', 'grid'] : 'grid'
          }
          gridTemplateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            `repeat(${maxItemsPerRow}, minmax(0, ${max_item_width}px))`
          ]}
        >
          {items.map(renderGridItem)}
        </Grid>
      </Container>
    </Box>
  )
}
