import { Button } from '@tofu/shared/ui/atoms/button'

export const Arrow = ({
  children,
  disabled,
  onClick
}: {
  children: React.ReactNode
  disabled: boolean
  onClick: VoidFunction
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{
        borderRadius: '100%',
        width: '40px',
        height: '40px',
        background: 'rgba(0,0,0,.5)',
        zIndex: 1,
        color: 'white',
        opacity: disabled ? '0!important' : '1',
        display: 'flex',
        mx: 4,
        '&:hover': {
          background: 'rgba(0,0,0,.7)'
        }
      }}
      variant='ghost'
    >
      {children}
    </Button>
  )
}
