// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Image from 'next/image'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { LinkBox, LinkOverlay } from '@chakra-ui/react'

import { Text } from '@tofu/shared/ui/atoms/text'

import {
  TSliceImageGridItem,
  TRenderContent
} from './slice-image-grid-item.types'

const renderContent: TRenderContent = (image, link, description) => (
  // /* istanbul ignore next */
  <motion.div whileHover={{ scale: link ? 0.95 : 1 }}>
    {image?.url && (
      <Image
        src={image.url}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        alt={image?.name}
        layout='responsive'
        width={image.width}
        height={image.height}
      />
    )}

    {description ? (
      <Text as='span' fontWeight='book' px={3} display='block' mt={2}>
        {description}
      </Text>
    ) : null}
  </motion.div>
)

export const SliceImageGridItem: TSliceImageGridItem = ({
  image,
  link,
  description,
  ...rest
}) => (
  <LinkBox
    textAlign='center'
    width='100%'
    maxWidth={['100%', 230, 230]}
    gap={3}
    display='flex'
    flexDirection='column'
    tabIndex={0}
    {...rest}
  >
    {!link && renderContent(image, link, description)}

    {link && (
      // /* istanbul ignore next */
      <LinkOverlay href={link}>
        <Link href={link} passHref>
          {renderContent(image, link, description)}
        </Link>
      </LinkOverlay>
    )}
  </LinkBox>
)
