import React from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { ChevronLeftIcon } from '@tofu/shared/ui/atoms/icons'

import { Arrow } from '../scrolling-slider-arrow'

export const ScrollingSliderArrowLeft = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext)

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  )
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length > 0) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleElements])

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <ChevronLeftIcon />
    </Arrow>
  )
}
