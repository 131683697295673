import 'react-horizontal-scrolling-menu/dist/styles.css'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

import { Box } from '@tofu/shared/ui/atoms/box'

import { ScrollingSliderArrowLeft } from './scrolling-slider-arrow-left'
import { ScrollingSliderArrowRight } from './scrolling-slider-arrow-right'

import { onWheel } from './scrolling-slider.utils'
import { TScrollingSlider } from './scrolling-slider.types'

export const ScrollingSlider: TScrollingSlider = ({
  children,
  sx,
  ...rest
}) => {
  if (!children) return null

  return (
    <Box
      sx={{
        '.react-horizontal-scrolling-menu--wrapper': {
          position: 'relative'
        },
        '.react-horizontal-scrolling-menu--arrow-right, .react-horizontal-scrolling-menu--arrow-left':
          {
            height: '100%',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center'
          },
        '.react-horizontal-scrolling-menu--arrow-right': {
          right: '0'
        },
        '.react-horizontal-scrolling-menu--arrow-left': {
          left: '0'
        },
        ...sx
      }}
    >
      <ScrollMenu
        onWheel={onWheel}
        LeftArrow={ScrollingSliderArrowLeft}
        RightArrow={ScrollingSliderArrowRight}
        {...rest}
      >
        {children}
      </ScrollMenu>
    </Box>
  )
}
